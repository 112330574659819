import React, { useEffect, useState } from "react";
import security from "../assets/security.webp"
import cloud from "../assets/cloud-computing.webp"
import instability from "../assets/regulation.webp"
import scan from "../assets/eye-scan.webp"
import protection from "../assets/file-protection.webp"
import inspection from "../assets/inspection.webp"
import product from "../assets/product.webp"
import system from "../assets/security-system.webp"
import networking from "../assets/networking.webp"
import national from "../assets/national-security-agency.webp"
import suspect from "../assets/suspect.webp"
import menu from "../assets/analysis.webp";
import { Link } from "react-router-dom";

import { StorageLink } from "../../app/Apilink";

import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../features/categories/categorySlice';

const initialCategories = [
  { link: "/gym", icon: security, title: "Cyber Security", count: 1 },
  { link: "/hotel", icon: cloud, title: "Cloud Security", count: 0 },
  { link: "/travel", icon: scan, title: "Data Privacy", count: 2 },
  { link: "/furniture", icon: inspection, title: "Auditing", count: 1 },
  {
    link: "/electronics",
    icon: protection,
    title: "Security Testing",
    count: 2,
  },
  { link: "/resort", icon: product, title: "Product Trainings", count: 0 },
  { link: "/medical", icon: system, title: "Security Operation", count: 1 },
  { link: "/education", icon: security, title: "Offensive Security", count: 1 },
  { link: "/realestate", icon: national, title: "Defensive Security", count: 1 },
  { link: "/ecommerce", icon: suspect, title: "Identity & Access", count: 1 },
  { link: "/shopping", icon: instability, title: "Governance, Risk & Compliance", count: 2 },
  { link: "/fitness", icon: networking, title: "Network Security Training", count: 1 },
];

const Herotwo = () => {
  const [categories, setCategories] = useState([]);
  const [visibleIndex, setVisibleIndex] = useState(3); 
  const [viewAll, setViewAll] = useState(false);


 

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setCategories(initialCategories.slice(0, visibleIndex));
  }, [visibleIndex]);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 767) {
      setVisibleIndex(7); // Mobile, show 3 cards
    } else if (width <= 991) {
      setVisibleIndex(5); // Tablet, show 5 cards
    } else {
      setVisibleIndex(7); // Laptop and above, show 7 cards
    }
  };

  const handleViewAllClick = () => {
    setViewAll(true);
  };

  const visibleCategories = viewAll ? initialCategories : categories;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const dispatch = useDispatch();
  const categoriess = useSelector((state) => state.categories.data);
  const status = useSelector((state) => state.categories.status);
  const error = useSelector((state) => state.categories.error);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCategories());
    }
   
  }, [status, dispatch]);


  return (
    <section className="category-section category-main-hero">
      <div className="container">
        <div className="row g-3 g-lg-4">
          {/* Map over the visible categories */}
          {categoriess.map((category, index) => (
            <div key={index} className="col-xl-3 col-md-6 col-4">
              <Link
                to={`/courses/${category.slug}`}
              >
                <div className="category-box" data-aos="flip-left">
                  <div className="icon-box">
                    <img className="cate-img" src={`${StorageLink}/${category.icon}`} alt="" />
                  </div>
                  <div>
                    <h5>{category.name}</h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
          {/* Show View All button after the third category if not already viewing all */}
          {!viewAll && (
            <div className="col-xl-3 col-md-6 col-3">
              <div className="category-box" onClick={handleViewAllClick} >
                <div className="icon-box" data-aos="flip-right">
                  <img src={menu} alt="" />
                </div>
                <div>
                  <h5>View All Courses</h5>
        
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Herotwo;
