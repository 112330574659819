import { useEffect , useState} from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaBasketballBall, FaLocationArrow, FaPhoneVolume, FaEnvelope } from 'react-icons/fa';
import { Link } from "react-router-dom";
import imag from "../assets/Blue.webp"
import axios from 'axios'
import { BaseLink } from "../../config/ApiLink";
import Swal from "sweetalert2";
const ContactUs = () => {
  const [contactDetails, setContactDetails] = useState('');
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phonenumber: "",
    message: ""


  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle form submission logic here
    
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Send a POST request with the form data
      const response = await axios.post(`${BaseLink}/submitrespons`, {
        ...formData, // Spread the formData to include all its properties
      });
  

  
      // If successful, show a SweetAlert confirmation
      Swal.fire({
        title: "Success!",
        text: "Your form has been submitted successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });


      window.location.href = '/'


    } catch (error) {
      console.log(error);
  
      // Show an error alert
      Swal.fire({
        title: "Error!",
        text: "There was an error submitting your form. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });

    }
  };

  

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await axios.get(`${BaseLink}/contactdetails`);
        setContactDetails(response.data[0]); // Assuming the response is an object with required fields
      } catch (error) {
        console.error("Error fetching contact details:", error);
      }
    };

    fetchContactDetails();
  }, []);
  const { address, contact_number, email } = contactDetails || {};


  const links = contactDetails.social_links || {};


  return (
    <>
      <section className="rows bg-fixed privacy-banner">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-7">
              <h1 className="text-white font-weight-bold home_h2 wow  fadeInDown   animated">
          
                Contact Us
              </h1>

              <p className="mt-2 text-light">Submit Your Query </p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-form px-md-3 px-sm-0 py-5">
        <div className="container">
          <div className="row">
          <div className="col-lg-6">
       
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109782.87829261528!2d76.61497891320774!3d30.698295388807644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee906da6f81f%3A0x512998f16ce508d8!2sSahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab!5e0!3m2!1sen!2sin!4v1720091395240!5m2!1sen!2sin"
      className="map-container"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
            </div>
            <div className="home-main-fom col-lg-6">
            
    <div
      className="contact-main__form fade-top"
      style={{ transform: "translate(0px, 0px)", opacity: 1 }}
    >
      <h3>Leave a Message</h3>
      <form onSubmit={handleSubmit} className="section__content-cta">
        <div className="group-wrapper mb-3">
          <div className="group-input mb-3">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="group-input mb-3">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={formData.contactEmail}
              onChange={handleChange}
              className="form-control"
            />
          </div>
         
        </div>
        <div className="group-input">
            <input
              type="number"
              name="phonenumber"
              id="phonenumber"
              placeholder="Phone Number"
              value={formData.phonenumber}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        <div className="group-input mb-6">
          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="form-select"
          >
            <option value="" disabled>
              Subject
            </option>
            <option value="Account">Account</option>
            <option value="Service">Service</option>
            <option value="Pricing">Pricing</option>
            <option value="Support">Support</option>
          </select>
        </div>
        <div className="group-input mb-3">
          <textarea
            name="message"
            id="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            className="form-control "
            style={{height:'100px'}}
          ></textarea>
        </div>
        <div className="form-cta justify-content-start">
          <button type="submit" className="btn btn-submit-contact">
            Send Message
          </button>
        </div>
      </form>
    </div>
    </div>

          
          </div>
        </div>
      </section>

   
      <div className="container">
      <div className="row gaper justify-content-around">
        <div className="col-12 col-lg-5 col-xl-4">
          <div className="footer-two__left">
              <div className="d-flex gap-3 section__content-cta my-3">
            <div className="logo">
              <Link to="/">
                <img 
                  alt="Image"
                  fetchpriority="high"
                  width="160"
                  height="40"
                  decoding="async"
                  style={{color: 'transparent'}}
                 src={imag}
                />
              </Link>
            </div>
              <h2>
                <Link className="folks-text animated-text" to={`mailto:${email}`}>
                  {email}
                </Link>
              </h2>
            </div>
           

          </div>
        </div>
        <div className="col-12 col-lg-7 col-xl-7">
        {Object.keys(links).length > 0 ? (
        <div className="social_contact">
          {links.Facebook && (
            <Link target="_blank" to={links.Facebook}>
              <FaFacebookF />
              <span>Facebook</span>
            </Link>
          )}
          {links.Twitter && (
            <Link target="_blank" to={links.Twitter}>
              <FaTwitter />
              <span>Twitter</span>
            </Link>
          )}
          {links.LinkedIn && (
            <Link target="_blank" to={links.LinkedIn}>
              <FaLinkedinIn />
              <span>LinkedIn</span>
            </Link>
          )}
          {links.Instagram && (
            <Link target="_blank" to={links.Instagram}>
              <FaInstagram />
              <span>Instagram</span>
            </Link>
          )}
          {/* {links.YouTube && (
            <Link target="_blank" to={links.YouTube}>
              <FaYoutube />
              <span>YouTube</span>
            </Link>
          )} */}
        </div>
      ) : (
        <p>No social links available</p>
      )}
    </div>
      </div>
    </div>
    
    </>
  );
};

export default ContactUs;
