import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { reviewData } from "../CommonData/CardsDetailData";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";


const Testimonial = ({val}) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: val ? val : 3 ,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  
  return (
    <>
      <div
        className="domain-main overflow-hidden"
        id="reviews"
        data-aos=""
        data-aos-anchor-placement="center-center"
      >
        <div className="mb-3 domain-content position-relative">
          <div className="max-w-133">
            <h2 className="text-sm text-uppercase font-semibold text-muted text-center">
              LEARNER REVIEWS FROM THE WORLD OVER
            </h2>
            <h3 className="text-3xl font-weight-bold text-black mt-3 text-center">
              Testimonials That Speak Volumes
            </h3>
          </div>
        </div>

        <div
          className="container py-2 testimonial-buttons"
          data-aos="fade-up-right"
        >
          <Slider {...settings}>
            {reviewData.map((review, index) => (
              <div key={index}>
                <div className="position-relative mt-4">
                  <div className="testimonial-slider">
                    <div className="custom-quote d-flex align-items-center rounded-circle justify-content-center bg-white position-absolute left-0 top-min-4">
                      <i className="bi bi-quote text-custom32 text-neutral-200"></i>
                    </div>
                    <div className="w-100">
                      <h6 className="text-lg text-dark fw-bold mb-2">
                        {review.title}
                      </h6>
                      <div className="d-flex gap-2">
                        {[...Array(review.rating)].map((_, index) => (
                          <i
                            key={index}
                            className="bi bi-star-fill text-star text-2xl"
                          ></i>
                        ))}
                      </div>
                      <div className="mt-3">
                        <p className="text-sm text-muted text-justify">{review.content}</p>
                      </div>
                    </div>
                    <div className="w-100 border-custom"></div>
                    <div className="d-flex align-items-center gap-3">
                      <div
                        className="rounded-lg overflow-hidden"
                        style={{ width: "48px", height: "48px" }}
                      >
                        <img
                          src={review.author.imageUrl}
                          alt="user image"
                          className="w-100 h-100"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <div className="text-sm text-dark fw-bold text-truncate">
                          {review.author.name}
                        </div>
                        <div className="text-xs text-muted text-truncate">
                          {review.author.role}
                        </div>
                      </div>
                      <Link
                        to={review.author.profileLink}
                        className="text-decoration-none text-dark text-center"
                      >
                        <img
                          src={review.author.image}
                          alt="social icon"
                          style={{ color: "transparent" }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        
      </div>
    </>
  );
};

export default Testimonial;
